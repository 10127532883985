import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { usePagination } from "../../Context/PaginationContext";
import Table from "../../Components/Table/Table";
import Pagination from "../../Components/Pagination/Pagination";
import { useParams } from "react-router-dom";
import UpgradeModal from "../../Components/Modal/UpgradeStripeModal/UpgradeStripeModal";
import { ReactComponent as LockIcon } from './../../assets/Icons/lock_icon.svg'
import { LeadType } from "../../Components/PrivateRoute/types";

const GET_LEADS = gql`
  mutation getLeads($data: LeadsListInput!) {
    getLeads(data: $data) {
      total
      data {
        id
        email
        contact
        website
        description
        created_at
        agency_id
        status
      }
    }
  }
`;
interface LeadsProps {
  pageContent?: any;
  leadType?: LeadType,
}

const Leads: React.FC<LeadsProps> = ({ pageContent, leadType }) => {
  const { currentPage, itemsPerPage, setItemPerPage, setCurrentPage } = usePagination('leadsPagination');
  const [userAgency, setUserAgency] = useState<any>(null);
  const params = useParams();
  const [leads, setLeads] = useState([]);
  const [openModal, setopenModal] = useState(false)
  const [leadsCount, setleadsCount] = useState(-1);
  const table_header = [
    { name: "created_at", title: "Date", width: "130px" },
    { name: "email", title: "Email", width: "230px" },
    { name: "contact", title: "Phone no", width: "180px" },
    { name: "website", title: "Website", width: "200px" },
    { name: "description", title: "Description", width: 130 + 230 + 180 + 200 },
  ];

  const leadRef = useRef(null);

  const Data = [
    { created_at: "Lorem Ipsum", email: "Lorem Ipsum", contact: "Lorem Ipsum", website: "Lorem Ipsum", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. " },

    { created_at: "Lorem Ipsum", email: "Lorem Ipsum", contact: "Lorem Ipsum", website: "Lorem Ipsum", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. " },

    { created_at: "Lorem Ipsum", email: "Lorem Ipsum", contact: "Lorem Ipsum", website: "Lorem Ipsum", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. " },

    { created_at: "Lorem Ipsum", email: "Lorem Ipsum", contact: "Lorem Ipsum", website: "Lorem Ipsum", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. " },

    { created_at: "Lorem Ipsum", email: "Lorem Ipsum", contact: "Lorem Ipsum", website: "Lorem Ipsum", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. " },

  ]

  const [getLeads] = useMutation(GET_LEADS);

  const getLeadsFunc = async (page) => {
    const result = await getLeads({
      variables: {
        data: {
          page: page,
          pageSize: 5,
          agency_id: parseInt(`${params.id}`),
          type: leadType && leadType
        },
      },
    });
    setLeads(result?.data?.getLeads?.data || []);
    setleadsCount(result?.data?.getLeads?.total);
  };



  const userAgencyData = localStorage.getItem("user_agency");

  useEffect(() => {
    try {
      if (
        userAgencyData !== undefined &&
        userAgencyData !== "undefined" &&
        JSON.parse(userAgencyData || '')
      ) {
        let agency = JSON.parse(userAgencyData || '');
        setUserAgency(agency?.agency || null);
        console.log(agency?.agency, "userAgencyData213");

        if (agency?.agency.status === "unverified" || agency?.agency.status === "hidden") {
          return
        }
        getLeadsFunc(currentPage);
      }
    } catch (error) { }


  }, [userAgencyData, currentPage]);

  useEffect(() => {
    if (leadRef.current && currentPage > 1) {
      (leadRef.current as HTMLElement).scrollIntoView({ behavior: "smooth" });
    }

  }, [currentPage]);
  useEffect(() => {
    return (() => {
      setCurrentPage(1)
    })
  }, [])

  return (
    <>
      <div
        ref={leadRef}
        id="snapshot"
        style={{ display: `${userAgency?.status === "unverified" ? 'none' : 'flex'}`, flexDirection: "column", width: '100%', paddingTop: '12px', minHeight: '300px', marginTop: `${leads.length <= 1 ? '20px' : '0px'}` }}
        className="px-[5%] xl:px-[10px]"
      >
        <h6
          className="text-[24px] font-semibold  font-inter tracking-[0.44px] mb-2"
          style={{ marginBottom: "24px" }}
        >
          {`${leadType === LeadType.WARM ? 'Warm' : 'Hot'}`} Leads
        </h6>

        <>
          {
            <UpgradeModal
              open={openModal}
              onClose={() => { setopenModal(false) }}
              agencyId={userAgency?.id}
            />
          }
          <div style={{ position: 'relative' }}>
            {
              (userAgency?.status !== "verified" && userAgency?.status === "free") &&

              <div
                style={{
                  width: '362px',
                  height: '260px',
                  backgroundColor: 'white',
                  boxShadow: '#101828 0 0 6px -4px',
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1,
                  margin: 'auto',
                  borderRadius: '12px',
                  padding: '24px',
                  marginTop: `${leads.length <= 1 ? '0px' : 'auto'}`
                }}
              >
                <div>
                  <LockIcon />
                  <h4
                    className='mt-[16px] text-[18px] font-[600]'
                  >
                    Upgrade your plan to unlock leads
                  </h4>
                  <span
                    className='mt-[16px] text-[14px] font-[400]'
                  >
                    You are in a free plan, you can upgrade your plan to unlock potential leads.
                  </span>
                </div>
                <button
                  onClick={() => { setopenModal(true) }}
                  style={{ border: 'none', color: '#FFF' }}
                  className='rounded-[8px] bg-[#329BFA] mt-[32px] w-[100%] px-[18px] py-[10px] text-[16px] font-[600]'
                >
                  Upgrade
                </button>
              </div>
            }
           
              <Table leadType = {leadType} emptyMessage={`No ${leadType?.toLowerCase()} lead found.`} header={table_header} rows={(userAgency?.status !== "verified") ? leadsCount === 0 ? Data : leads : leads} blur={userAgency?.status !== "verified"} />

            {leadsCount > 5 && (
              <Pagination
                dataCount={leadsCount}
                type={"for-leads"}
                blur={userAgency?.status !== "verified"}
                paginationKey={"leadsPagination"}
              />

            )}
          </div>

        </>
      </div>
    </>
  );
};

export default Leads;
